.container_super_user {
  width: 80%;
  display: grid;
  margin: 0 auto;

  small {
    font-size: 16px;
  }

  .graphs_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'contracts_sp users_sp';

    .contracts_sp {
      grid-area: contracts_sp;
    }

    .users_sp {
      grid-area: users_sp;
    }

    .select_year {
      text-align: center;
      select {
        width: 80%;
      }
    }

    .table_users,
    .table_contracts {
      p {
        text-align: center;
      }
      .head_table,
      .body_table {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        align-items: center;
        text-align: center;
      }

      .body_table {
        margin-top: 30px;
      }
    }
  }

  .container_info_signers {
    text-align: center;
    .inputs_to_search_sp {
      display: flex;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      div {
        width: 100%;
      }
    }

    .table_reports_container {
      .table_sife,
      .table_contracts_sp,
      .table_invited_sp {
        .header_table_sife,
        .body_table_sife,
        .hader_table_contracts_sp,
        .body_table_contracts_sp,
        .header_table_invited_sp,
        .body_table_invited_sp {
          display: grid;
          align-items: center;
          text-align: center;
        }

        .header_table_sife,
        .body_table_sife {
          grid-template-columns: repeat(7, 1fr);
          gap: 20px 5px;
        }

        .hader_table_contracts_sp,
        .body_table_contracts_sp,
        .header_table_invited_sp,
        .body_table_invited_sp {
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
        }
      }

      .spacing_tables {
        margin-top: 30px;
        margin-bottom: 30px;
        max-height: 400px;
        overflow: auto;
      }
    }
  }

  .container_coupons {
    text-align: center;
    .table_coupons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    .coupons_used {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 0 10px;
      align-items: center;
      max-height: 400px;
      overflow: auto;
    }
  }

  .container_api_users {
    text-align: center;
    .border_api {
      padding: 10px;
      border: 1px solid @color-gray;
      border-radius: 5px;
      margin: 30px 0;
    }
    .my_api_users {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    .api_templates {
      .header_api_templates,
      .body_api_templates {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }
    }

    .renovate_token,
    .api_request {
      .header_renovate_token,
      .body_renovate_token {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        gap: 10px;
      }
    }
  }

  .container_search_sp {
    .menu_container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .input_to_search_sp {
      margin: 0 auto;
      margin-top: 50px;
      width: 30%;
    }

    /* SEARCH CONTRACT BY ID AND LIST SIGNERS */
    .header_info_contract,
    .body_info_contract {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
      align-items: center;
      text-align: center;
      margin-bottom: 30px;
    }

    .header_list_signers_sp,
    .body_list_signers_sp {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      gap: 20px;
      align-items: center;
      text-align: center;
      margin-top: 20px;

      .signer_data {
        font-size: 14px;

        &.normal {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }

    /* SEARCH USER AND CONTRACTS */
    .header_user_register,
    .body_user_register {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;
      align-items: center;
      text-align: center;
    }

    .header_user_register {
      margin-bottom: 10px;
    }

    .search_sp:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }

    .header_table_contract,
    .body_table_contract {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px 20px;
      align-items: center;
      text-align: center;
    }

    .header_table_contract {
      margin-bottom: 10px;
    }

    .body_table_contract {
      max-height: 400px;
      overflow: auto;
    }

    /* ADMINS */
    .header_my_signers_sp,
    .body_my_signers_sp {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px 20px;
      align-items: center;
      text-align: center;
    }

    .header_my_signers_sp,
    .header_im_representing {
      margin-bottom: 10px;
    }

    .header_im_representing,
    .body_im_representing {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px 20px;
      align-items: center;
      text-align: center;
    }
  }

  .sp_admin_search {
    border: 1px solid #6c6c6c;
    color: #6c6c6c;
    min-width: 20%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin: 3px;

    small {
      padding: 10px;
    }

    &.active_menu_sp {
      border: 2px solid @color-primary;
      color: black;
      box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
    }
  }

  .bold {
    font-weight: bold !important;
    color: #b9b9b9;
  }

  .contract_for_me {
    font-weight: bold;
    color: black;
  }

  .other_status {
    font-weight: 400;
    color: #6c6c6c;
  }

  .normal_font {
    font-weight: 400;
    color: black;
  }

  .align_center {
    text-align: center !important;
  }

  .flex {
    display: flex;
    align-items: center;

    i {
      padding-left: 10px;
    }
  }

  // PERMISSIONS LESS
  .permissions-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: auto;
    margin: auto;

    .permissions-column {
      flex: 1;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;

      h3 {
        text-align: center;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          border-bottom: 1px solid #eee;

          button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.external-apis-table {
  width: 100%;
  margin: auto;
  text-align: center;

  h2 {
    margin-bottom: 15px;
  }

  table {
    width: 100%;
    border-collapse: collapse; /* Asegura que los bordes no se dupliquen */

    th,
    td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: left;
      vertical-align: middle; /* Alineación vertical centrada */
      word-break: break-word; /* Permite que el texto se divida en varias líneas */
    }

    th {
      background: #f4f4f4;
      white-space: nowrap; /* Evita que los encabezados se dividan */
    }

    tr {
      display: table-row;
      vertical-align: inherit;
    }

    tr:hover {
      background: #f9f9f9;
    }
  }
}
